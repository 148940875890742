<script setup lang="ts">
import {useModal} from "vuestic-ui";
import swal from "sweetalert2";
import {computed, ref, reactive, watch} from "vue";
import AddProxyForm from "@/components/AddProxyForm.vue";
import api from "@/services/proxy-api";
import {Proxy} from "@/models/Proxy";
import DataTable from "@/components/DataTable.vue";
import {formatDateTime} from "../utils/datetime";
import * as XLSX from 'xlsx';
import {Provider} from "@/models/Provider";
import {Credential} from "@/models/Credential";
import {CreateProxyRequest} from "@/services/proxy-api/models/requests";
import {PaginatedResults} from "@/models/PaginatedResults";

const {confirm} = useModal();

const proxies = ref<PaginatedResults<Proxy>>({records: [], pagination: {page: 0, page_size: 0, total_records: 0, total_pages: 0}});
const providers = ref<Provider[]>([]);
const credentials = ref<Credential[]>([]);
const isLoading = ref(false);
const isInitializedUploadModal = ref(false);

const showAddProxyModal = ref(false);
const showRetiredProxies = ref(true);
const addProxyFormRef = ref<InstanceType<typeof AddProxyForm> | null>(null);
const selectedItems = ref<Proxy[]>([]);
const currentPage = ref(1);
const pageSize = ref(10);
const showUploadModal = ref(false);
const batchUpload = reactive<{ ProviderId: number | null, CredentialId: number | null, Verify: boolean }>({
	ProviderId: null,
	CredentialId: null,
	Verify: true,
});
const uploadedFile = ref<File | null>(null);
const requiredBatchColumns = ['Host', 'Port', 'Subnet', 'Country', 'State'];

const columns = [
	{key: "ProxyId", label: "ID", sortable: true},
	{key: "Host", sortable: true},
	{key: "Port", sortable: true},
	{key: "Country", sortable: true},
	{key: "Provider.Name", label: "Provider", sortable: true},
	{key: "CreatedDateUtc", label: 'Created Date', sortable: true},
	{key: "RetiredDateUtc", label: 'Retired Date', sortable: true},
	{key: "actions", width: 80},
];

const fetchData = async () => {
	isLoading.value = true;
	try {
		proxies.value = await api.getProxiesVerbose(undefined, undefined, {pageSize: pageSize.value, pageIndex: currentPage.value});
	} catch (e: any) {
		await swal.fire('Error', e.message, 'error');
	} finally {
		isLoading.value = false;
	}
};

const initializeUploadModal = async () => {
	if (!isInitializedUploadModal.value) {
		try {
			const [_providers, _credentials] = await Promise.all([
				api.getProviders(undefined, undefined, { pageSize: 500 }),
				api.getCredentials({pageSize: 500}),
			]);
			providers.value = _providers.records;
			credentials.value = _credentials.records;
			isInitializedUploadModal.value = true;
		} catch (e: any) {
			await swal.fire('Error', e.message, 'error');
		}
	}
};

const askDeleteConfirmation = async (proxy: Proxy) => {
	const ok = await confirm(`Are you sure you want to delete this proxy (ID: ${proxy.ProxyId})?`)
	if (ok) {
		try {
			await api.deleteProxy(proxy.ProxyId);
		} catch (e) {
			await swal.fire('Error', 'Failed to delete proxy.', 'error');
		} finally {
			await fetchData()
		}
	}
};

const askDeleteBulkConfirmation = async () => {
	const ok = await confirm(`Are you sure you want to delete ${selectedItems.value.length} selected ${selectedItems.value.length === 1 ? 'proxy' : 'proxies'}?`)
	if (ok) {
		const failedToDelete: number[] = [];
		const promises = selectedItems.value.map(async (item: Proxy) => {
			try {
				await api.deleteProxy(item.ProxyId);
			} catch (e) {
				failedToDelete.push(item.ProxyId);
			}
		});
		await Promise.all(promises);
		if (failedToDelete.length) {
			await swal.fire('Error', `Failed to delete the proxies with IDs: ${failedToDelete.join(', ')}`, 'error');
		} else {
			await swal.fire('Success', 'All selected proxies deleted successfully.', 'success');
		}
		await fetchData()
		selectedItems.value = [];
	}
};

const createProxy = async (proxy: CreateProxyRequest) => {
	try {
		const response = await api.createProxy(proxy);
		switch (response.status) {
			case 400:
				let errorMessage = '';
				for (const field in response.data.errors) {
					errorMessage += `${field}: ${response.data.errors[field].join(', ')}\n`;
				}
				await swal.fire(response.data.title, errorMessage, 'error');
				break;
			case 422:
				await swal.fire(response.data.title, response.data.detail, 'error');
				break;
			case 500:
				await swal.fire(response.data.title,`An error occurred while adding the proxy ${proxy.Host}:${proxy.Port}.\nError:${response.data.detail}`, 'error');
		}
	} catch (any) {
		await swal.fire('Error', `An error occurred while adding the proxy ${proxy.Host}:${proxy.Port}. See console log for details.`, 'error');
		console.error(any);
	} finally {
		await fetchData()
	}
};

const filteredProxies = computed<PaginatedResults<Proxy>>(() => {
	if (showRetiredProxies.value) {
		return proxies.value;
	}
	return { ...proxies.value, records: proxies.value.records.filter((proxy: Proxy) => !proxy.RetiredDateUtc) }
});

const handleFileUpload = (event: Event) => {
	if (event.target) {
		const target = event.target as HTMLInputElement;
		if (target.files) {
			uploadedFile.value = target.files[0];
		}
	}
};

const uploadProxies = async () => {
	if (!uploadedFile.value) {
		await swal.fire('Error', 'Please select a file to upload.', 'error');
		return;
	}
	if (!batchUpload.ProviderId) {
		await swal.fire('Error', 'Please select a provider.', 'error');
		return;
	}

	const reader = new FileReader();
	reader.onload = async (e) => {
		if (e.target) {
			const data = new Uint8Array(e.target.result as ArrayBuffer);
			const workbook = XLSX.read(data, {type: 'array'});

			const worksheetName = workbook.SheetNames[0];
			const worksheet = workbook.Sheets[worksheetName];

			let proxies = XLSX.utils.sheet_to_json(worksheet, {header: 1}) as Array<{ [key: string]: any }>;

			// Convert the column names in the file to lower case
			const fileColumnNames = proxies[0].map((column: string) => column.toLowerCase());

			// Check if all required columns are present
			const missingColumns = requiredBatchColumns.filter(column => !fileColumnNames.includes(column.toLowerCase()));

			if (missingColumns.length > 0) {
				// If any required columns are missing, show an error popup and return
				await swal.fire('Error', `The following required columns are missing: ${missingColumns.join(', ')}`, 'error');
				return;
			}

			// Skip the first row (column headers)
			proxies = proxies.slice(1);

			swal.showLoading();

			let uploadCount = 0;

			for (let i = 0; i < proxies.length; i += 10) {
				const batch = proxies.slice(i, i + 10);
				await Promise.all(batch.map(proxy => {
					if (Array.isArray(proxy)) {
						const [Host, Port, Subnet, Country, State] = proxy;
						const proxyObject: CreateProxyRequest = {
							Host,
							Port,
							Subnet,
							Country,
							StateCode: State,
							ProviderId: batchUpload.ProviderId,
							CredentialId: batchUpload.CredentialId,
							VerificationEnabled: batchUpload.Verify,
						};
						uploadCount++;
						return createProxy(proxyObject);
					}
				}));
			}
			batchUpload.ProviderId = null;
			batchUpload.CredentialId = null;
			swal.close();

			// Show success popup
			await swal.fire('Success', `${uploadCount} proxies uploaded successfully.`, 'success');
		}
	};
	reader.readAsArrayBuffer(uploadedFile.value);
};

fetchData();

watch(currentPage, async (newVal, oldVal) => {
	if (newVal !== oldVal) {
		await fetchData();
	}
});

watch(pageSize, async (newVal, oldVal) => {
	if (newVal !== oldVal) {
		await fetchData();
	}
});

watch(showUploadModal, async (newVal) => {
	if (newVal) {
		await initializeUploadModal();
	}
});

</script>

<template>
	<p class="font-bold text-2xl pb-3 text-center">Proxies</p>
	<DataTable
		v-model:selected-items="selectedItems"
		v-model:current-page="currentPage"
		v-model:page-size="pageSize"
		:items="filteredProxies"
		:columns="columns"
		:loading="isLoading"
		:can-edit="false"
		:on-create="async () => {
			showAddProxyModal = true;
		}"
		:on-delete="askDeleteConfirmation"
		:on-bulk-delete="askDeleteBulkConfirmation">
		<template #top-left>
			<VaSwitch
				v-model="showRetiredProxies"
				class="mr-3 mt-1.5"
				label="Show retired proxies"
				size="small"/>
		</template>
		<template #top-right>
			<VaButton
				v-if="true"
				class="mr-3"
				icon="upload"
				round
				@click="showUploadModal = true"
			>
				Upload
			</VaButton>
		</template>
		<template #cell(CreatedDateUtc)="{ value }">
			{{ value && formatDateTime(value) }}
		</template>
		<template #cell(RetiredDateUtc)="{ value }">
			{{ value && formatDateTime(value) }}
		</template>
	</DataTable>

	<VaModal
		v-model="showAddProxyModal"
		ok-text="Create"
		cancel-text="Cancel"
		close-button
		@ok="() => {
			createProxy(addProxyFormRef!.proxy)
		}"
	>
		<h2 class="mb-5 text-3xl font-extrabold text-gray-900">
			New Proxy
		</h2>
		<AddProxyForm ref="addProxyFormRef" :submit="createProxy" class="mb-3"></AddProxyForm>
	</VaModal>

	<VaModal
		v-model="showUploadModal"
		ok-text="Upload"
		cancel-text="Cancel"
		close-button
		@ok="uploadProxies"
	>
		<h2 class="mb-3 text-3xl font-extrabold text-gray-900">
			Upload Proxies
		</h2>
		<p>Please ensure that the file includes the following columns:</p>
		<ul class="list-disc list-inside my-2 ml-2">
			<li v-for="column in requiredBatchColumns" :key="column">{{ column }}</li>
		</ul>
		<p>You can download <a href="/assets/proxy-batch-example.csv" target="_blank">this template</a> to create your
			file.</p>
		<hr class="my-3">
		<div>
			<input class="my-3" type="file" accept=".xlsx, .xls, .csv" @change="handleFileUpload"/>
			<p class=" font-bold">Supported file formats: .xlsx, .xls, .csv</p>
		</div>

		<div class="grid md:grid-cols-2 gap-x-5 mt-4">
			<VaSelect
				class="mb-5"
				v-model="batchUpload.ProviderId"
				label="Provider"
				:options="providers"
				:value-by="(model: Provider) => model.ProviderId"
				:text-by="(model: Provider) => model.Name"
				searchable
				highlight-matched-text
			/>
			<VaSelect
				class="mb-5"
				v-model="batchUpload.CredentialId"
				label="Credential (Optional)"
				:options="credentials"
				:value-by="(model: Credential) => model.CredentialId"
				:text-by="(model: Credential) => `Username: ${model.Username}, Password: ${model.Password}`"
				searchable
				highlight-matched-text
			/>
			<VaCheckbox
				v-model="batchUpload.Verify"
				class="mb-6"
				label="Verify Proxy"
			/>
		</div>

	</VaModal>
</template>

<style scoped>

.landing-page h1 {
	margin-bottom: 20px;
}
</style>

<script setup lang="ts">
import { getInstance } from '@/auth/authWrapper';

const auth0 = getInstance();

const logout = () => {
	auth0?.logout();
};
</script>

<template>
	<button @click="logout">Log out</button>
</template>

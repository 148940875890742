<script setup lang="ts">
import {useModal} from "vuestic-ui";
import swal from "sweetalert2";
import {ref, watch} from "vue";
import api from "@/services/proxy-api";
import DataTable from "@/components/DataTable.vue";
import {Provider, ListItem} from "@/models/Provider";
import {PricingModel} from "@/models/PricingModel";
import {formatDateTime} from "@/utils/datetime";
import {CreateProviderRequest, UpdateProviderRequest} from "@/services/proxy-api/models/requests";
import {PaginatedResults} from "@/models/PaginatedResults";

const {confirm} = useModal();

const isLoading = ref(false);
const selectedItems = ref<Provider[]>([]);
const showCreateModal = ref(false);
const showEditModal = ref(false);
const currentPage = ref(1);
const pageSize = ref(10);

const columns = [
	{key: "ProviderId", label: "ID", sortable: true},
	{key: "Name", label: "Name", sortable: true},
	{key: "BillingInfo", label: "Billing Info", sortable: true},
	{key: "PricingModel.PricingType", label: "Pricing Model", sortable: true},
	{key: "ProxyType", label: "Type", sortable: true},
	{key: "CreatedDateUtc", label: 'Created Date', sortable: true},
	{key: "actions", width: 80},
];

const providers = ref<PaginatedResults<Provider>>({records: [], pagination: {page: 0, page_size: 0, total_records: 0, total_pages: 0}});
const pricingModels = ref<PricingModel[]>([]);
const proxyTypes: ListItem[] = [
	{
		text: "",
		value: null,
	},
	{
		text: "Free",
		value: "Free",
	},
	{
		text: "Data Center",
		value: "DataCenter",
	},
	{
		text: "Residential",
		value: "Residential",
	},
	{
		text: "Mobile",
		value: "Mobile",
	}];
const isInitialized = ref(false);
const newProvider = ref<CreateProviderRequest>({
	Name: '',
	BillingInfo: '',
	PricingType: '',
	ProxyType: null,
});
const editedProvider = ref<UpdateProviderRequest>({
	ProviderId: 0,
	Name: '',
	BillingInfo: '',
	PricingType: '',
	ProxyType: '',
});

const fetchData = async () => {
	isLoading.value = true;
	try {
		providers.value = await api.getProviders(undefined, undefined, {pageSize: pageSize.value, pageIndex: currentPage.value});;
		if (!isInitialized.value) {
			pricingModels.value = (await api.getPricingModels()).records;
			isInitialized.value = true;
		}

	} catch (e: any) {
		await swal.fire('Error', e.message, 'error');
	} finally {
		isLoading.value = false;
	}
};

const askDeleteConfirmation = async (provider: Provider) => {
	const ok = await confirm(`Are you sure you want to delete this provider (ID: ${provider.ProviderId})?`)
	if (ok) {
		try {
			await api.deleteProvider(provider.ProviderId);
		} catch (e) {
			await swal.fire('Error', 'Failed to delete provider.', 'error');
		} finally {
			await fetchData()
		}
	}
};

const askDeleteBulkConfirmation = async () => {
	const ok = await confirm(`Are you sure you want to delete ${selectedItems.value.length} selected ${selectedItems.value.length === 1 ? 'provider' : 'providers'}?`)
	if (ok) {
		const failedToDelete: number[] = [];
		const promises = selectedItems.value.map(async (item: Provider) => {
			try {
				await api.deleteProvider(item.ProviderId);
			} catch (e) {
				failedToDelete.push(item.ProviderId);
			}
		});
		await Promise.all(promises);
		if (failedToDelete.length) {
			await swal.fire('Error', `Failed to delete the providers with IDs: ${failedToDelete.join(', ')}`, 'error');
		} else {
			await swal.fire('Success', 'All selected providers deleted successfully.', 'success');
		}
		await fetchData()
		selectedItems.value = [];
	}
};

const createProvider = async (provider: CreateProviderRequest) => {
	try {
		await api.createProvider(provider);
	} catch (error: any) {
		if (error?.response?.status === 400) {
			let errorMessage = '';
			for (const field in error.response.data.Errors) {
				errorMessage += `${field}: ${error.response.data.Errors[field].join(', ')}\n`;
			}
			await swal.fire('Error', errorMessage, 'error');
		} else {
			await swal.fire('Error', 'An error occurred while adding the provider', 'error');
		}
	} finally {
		await fetchData()
	}
};

const updateProvider = async (provider: UpdateProviderRequest) => {
	try {
		await api.updateProvider(provider);
	} catch (error: any) {
		if (error?.response?.status === 400) {
			let errorMessage = '';
			for (const field in error.response.data.Errors) {
				errorMessage += `${field}: ${error.response.data.Errors[field].join(', ')}\n`;
			}
			await swal.fire('Error', errorMessage, 'error');
		} else {
			await swal.fire('Error', 'An error occurred while updating the provider', 'error');
		}
	} finally {
		await fetchData()
	}
};

fetchData();

watch(currentPage, async (newVal, oldVal) => {
	if (newVal !== oldVal) {
		await fetchData();
	}
});

watch(pageSize, async (newVal, oldVal) => {
	if (newVal !== oldVal) {
		await fetchData();
	}
});
</script>

<template>
	<p class="font-bold text-2xl pb-3 text-center">Providers</p>
	<DataTable
		v-model:selected-items="selectedItems"
		v-model:current-page="currentPage"
		v-model:page-size="pageSize"	
		:items="providers"
		:columns="columns"
		:loading="isLoading"
		:on-create="async () => {
			showCreateModal = true;
		}"
		:on-edit="async (provider: Provider) => {
			editedProvider = {
				ProviderId: provider.ProviderId,
				Name: provider.Name,
				BillingInfo: provider.BillingInfo ?? '',
				PricingType: provider.PricingModel?.PricingType ?? '',
				ProxyType: provider.ProxyType,
			};
			showEditModal = true;
		}"
		:on-delete="askDeleteConfirmation"
		:on-bulk-delete="askDeleteBulkConfirmation">
		<template #cell(CreatedDateUtc)="{ value }">
			{{ value && formatDateTime(value) }}
		</template>
	</DataTable>

	<VaModal
		v-model="showCreateModal"
		ok-text="Create"
		cancel-text="Cancel"
		close-button
		@ok="() => {
			createProvider(newProvider);
	  	}"
	>
		<h2 class="mb-6 text-3xl font-extrabold text-gray-900">
			New Provider
		</h2>
		<div class="grid md:grid-cols-2 gap-x-5">
			<VaInput
				v-model="newProvider.Name"
				label="Name"
				placeholder="Enter the provider name"
				class="mb-5"></VaInput>
			<VaInput
				v-model="newProvider.BillingInfo"
				label="Billing Info"
				placeholder="Enter the billing information"
				class="mb-5"></VaInput>
			<VaSelect
				class="mb-5"
				v-model="newProvider.PricingType"
				label="Pricing Model"
				:options="pricingModels"
				:value-by="(model: PricingModel) => model.PricingType"
				:text-by="(model: PricingModel) => model.PricingType"
				searchable
				highlight-matched-text
			/>
			<VaSelect
				class="mb-5"
				v-model="newProvider.ProxyType"
				label="Type"
				:options="proxyTypes"
				:value-by="(option: ListItem) => option.value"
				:text-by="(option: ListItem) => option.text"
				track-by="value"
			/>
		</div>
	</VaModal>
	<!-- Edit Provider Modal -->
	<VaModal
		v-model="showEditModal"
		ok-text="Save"
		cancel-text="Cancel"
		close-button
		@ok="() => {
			updateProvider(editedProvider);
		}"
	>
		<h2 class="mb-6 text-3xl font-extrabold text-gray-900">
			Edit Provider
		</h2>
		<div class="grid md:grid-cols-2 gap-x-5">
			<VaInput
				v-model="editedProvider.Name"
				label="Name"
				placeholder="Enter the provider name"
				class="mb-5"></VaInput>
			<VaInput
				v-model="editedProvider.BillingInfo"
				label="Billing Info"
				placeholder="Enter the billing information"
				class="mb-5"></VaInput>
			<VaSelect
				class="mb-5"
				v-model="editedProvider.PricingType"
				label="Pricing Model"
				:options="pricingModels"
				:value-by="(model: PricingModel) => model.PricingType"
				:text-by="(model: PricingModel) => model.PricingType"
				searchable
				highlight-matched-text
			/>
			<VaSelect
				class="mb-5"
				v-model="editedProvider.ProxyType"
				label="Type"
				:options="proxyTypes"
				:value-by="(option: ListItem) => option.value"
				:text-by="(option: ListItem) => option.text"
				track-by="value"
			/>
		</div>
	</VaModal>
</template>

<style scoped>

.landing-page h1 {
	margin-bottom: 20px;
}
</style>

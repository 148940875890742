import { getAccessTokenSilently } from "@/auth/authWrapper";

export const getToken = async () => {
	try {
		return await getAccessTokenSilently();
	} catch (error) {
		console.error("Error getting token", error);
		throw error;
	}
};

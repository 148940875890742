// router.ts
import { createRouter, createWebHistory } from 'vue-router';
import { getInstance } from './auth/authWrapper';
import Home from "./components/Home.vue";
import Callback from './components/Callback.vue';
import Logout from './components/Logout.vue';
import Groups from "./components/Groups.vue";
import GroupDetails from './components/GroupDetails.vue';
import Proxies from "./components/Proxies.vue";
import Providers from "@/components/Providers.vue";
import Credentials from "@/components/Credentials.vue";


const routes = [
	{ path: '/', component: Home, name: 'HomePage' },
	{ path: '/proxies', component: Proxies, name: 'Proxies' },
	{ path: '/providers', component: Providers, name: 'Providers' },
	{ path: '/groups', component: Groups, name: 'Groups' },
	{ path: '/callback', component: Callback, name: 'Callback' },
	{ path: '/logout', component: Logout, name: 'Logout' },
	{ path: '/groups/:groupId', component: GroupDetails, name: 'GroupDetails' },
	{ path: '/credentials', component: Credentials, name: 'Credentials' },
];

const router = createRouter({
	history: createWebHistory(),
	routes
});

router.beforeEach(async (to, _from, next) => {
	const auth0 = getInstance();
	while (auth0?.isLoading.value) {
		await new Promise(resolve => setTimeout(resolve, 100));
	}

	if (to.path === '/callback') {
		next();
	} else if (to.path === '/logout') {
		auth0?.logout();
		next({ path: '/' });
	} else if (auth0 && auth0.isAuthenticated.value) {
		if(to.path === '/') {
			next({ path: '/proxies' });
		} else {
			next();
		}
	} else if (to.path !== '/') {
		next({ path: '/' });
	} else {
		next();
	}
});

export default router;

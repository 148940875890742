<template>
	<form @submit.prevent="onSubmit" class="grid grid-cols-3 gap-y-5 gap-x-3">
		<div v-for="key in proxyKeys" :key="key">
			<Dropdown :id="key" :name="key" :label="functionProvider[key]?.fieldDisplayName || key"
				:display.sync="displayValues[key] || ''" :isRequired="requiredFields.includes(key)"
				:placeholder="functionProvider[key]?.fieldDisplayName || key" :fetchData="fetchFunctions[key]"
				:selectItem="selectFunctions[key]" :data="() => dataValues[key]"
				:displayKey="functionProvider[key]?.displayKey" :idKey="functionProvider[key]?.idKey"
				:updateDisplay="(value: string) => displayValues[key] = value"
				@update:localDisplay="updateProxy($event, key)" />
		</div>
		<div class="grid content-center pt-3">
			<VaCheckbox v-model="proxy.VerificationEnabled" class="" color="primary" label="Verify Proxy" />
		</div>
	</form>
</template>

<script setup lang="ts">
import {ref} from 'vue';
import axios from 'axios';
import swal from 'sweetalert2';
import {getToken} from '@/utils/auth';
import Dropdown from './Dropdown.vue';
import {CreateProxyRequest} from "@/services/proxy-api/models/requests";

const props = defineProps<{
	submit: (proxy: CreateProxyRequest) => Promise<void>
}>()

const apiServerUrl = import.meta.env.VITE_API_SERVER_URL

const onSubmit = async () => {
	await props.submit(proxy.value);
};

type DisplayValuesType = {
	[key: string]: string;
	Host: string;
	Port: string;
	Subnet: string;
	Country: string;
	StateCode: string;
};

type DataValuesType = {
	[key: string]: any[];
};

type FetchFunctionsType = {
	[key: string]: () => Promise<void>
};
type SelectFunctionsType = {
	[key: string]: (item: any) => void
};

const proxy = ref<CreateProxyRequest>({
	Host: '192.168.1.1',
	Port: 8080,
	Subnet: '255.255.255.0',
	Country: 'US',
	StateCode: 'NY',
	ProviderId: 1,
	CredentialId: null,
	Note: null,
	AnonymityId: null,
	ArinId: null,
	GroupId: null,
	VerificationEnabled: true
});

const proxyKeys = Object.keys(proxy.value).filter(key => key !== 'VerificationEnabled');

// TODO: consider using v-model instead of accessing this from the parent
defineExpose({
	proxy
})
const fetchData = async (url: string, key: string) => {
	try {
		const accessToken = await getToken();
		const response = await axios.get(apiServerUrl + url, {
			headers: {
				'Authorization': `Bearer ${accessToken}`
			},
			withCredentials: true
		});
		if (response.status === 200) {
			dataValues.value[key] = response.data.records;
		}
	} catch (error: any) {
		await swal.fire('Error', `Failed to fetch ${key}`, 'error');
	}
};

const selectItem = (item: any, key: string) => {
	proxy.value[key] = item[key];
};

const updateProxy = (newDisplay: string, key: string) => {
	proxy.value[key] = newDisplay;
};

const displayValues = ref<DisplayValuesType>({
	Host: proxy.value.Host ? proxy.value.Host.toString() : '',
	Port: proxy.value.Port ? proxy.value.Port.toString() : '',
	Subnet: proxy.value.Subnet ? proxy.value.Subnet.toString() : '',
	Country: proxy.value.Country ? proxy.value.Country.toString() : '',
	StateCode: proxy.value.StateCode ? proxy.value.StateCode.toString() : '',
});

const dataValues = ref<DataValuesType>({});
const fetchFunctions = ref<FetchFunctionsType>({});
const selectFunctions = ref<SelectFunctionsType>({});

const requiredFields = ref(['Host', 'Port', 'Subnet', 'Country', 'StateCode', 'ProviderId']);
const keys = ['ProviderId', 'CredentialId', 'AnonymityId', 'ArinId', 'GroupId'];
const urls = ['/proxies/providers', '/proxies/credentials', '/proxies/anonymities', '/proxies/arins', '/proxies/groups'];

abstract class DataProvider<T> {
	abstract select: (item: T) => Promise<void>;
	abstract fieldDisplayName: string;
	abstract displayKey: string | string[];
	abstract idKey: string;
}

class Provider extends DataProvider<Provider> {
	select = async (_item: Provider) => {};
	fieldDisplayName = 'Provider';
	displayKey = 'Name';
	idKey = 'Id';
}

class Credential extends DataProvider<Credential> {
	select = async (_item: Credential) => {};
	fieldDisplayName = 'Credential';
	displayKey = ['Username', 'Password'];
	idKey = 'Id';
}

class Anonymity extends DataProvider<Anonymity> {
	select = async (_item: Anonymity) => {};
	fieldDisplayName = 'Anonymity';
	displayKey = 'AnonymityName';
	idKey = 'Id';
}

class Arin extends DataProvider<Arin> {
	select = async (_item: Arin) => {};
	fieldDisplayName = 'Arin';
	displayKey = 'ArinCustomer';
	idKey = 'Id';
}

class Group extends DataProvider<Group> {
	select = async (_item: Group) => {};
	fieldDisplayName = 'Group';
	displayKey = 'GroupName';
	idKey = 'GroupId';
}

const functionProvider: Record<typeof keys[number], DataProvider<any>> = {
	ProviderId: new Provider(),
	CredentialId: new Credential(),
	AnonymityId: new Anonymity(),
	ArinId: new Arin(),
	GroupId: new Group(),
};

keys.forEach((key, index) => {
	fetchFunctions.value[key] = () => fetchData(urls[index], key);
	selectFunctions.value[key] = (item: any) => selectItem(item, key);
});
</script>

import {getToken} from "@/utils/auth";
import axios, {Method, AxiosInstance, AxiosResponse, AxiosError, CreateAxiosDefaults} from "axios";
import {Proxy} from "@/models/Proxy";
import {
	ApiResponse,
	BadRequestResponse,
	ServerErrorResponse,
	UnprocessableEntityResponse,
	NotFoundResponse,
	UnauthorizedResponse,
} from "@/services/proxy-api/models/response";
import {Group} from "@/models/Group";
import {PaginationOptions} from "@/models/PaginationOptions";
import {Provider} from "@/models/Provider";
import {PricingModel} from "@/models/PricingModel";
import {Credential} from "@/models/Credential";
import {
	CreateProxyRequest,
	CreateProviderRequest,
	UpdateProviderRequest,
	CreateCredentialRequest, UpdateCredentialRequest
} from "@/services/proxy-api/models/requests";
import {PaginatedResults} from "@/models/PaginatedResults";

const apiServerUrl = import.meta.env.VITE_API_SERVER_URL

async function request<T>(method: Method, url: string, data?: any, params?: any): Promise<T> {
	const accessToken = await getToken();
	const response = await axios({
		method,
		url: `${apiServerUrl}${url}`,
		params,
		data,
		headers: {
			'Authorization': `Bearer ${accessToken}`
		},
		withCredentials: true
	});
	return response.data;
}

async function makeApiCall<T>(
	method: Method,
	url: string,
	data?: any,
	params?: any
): Promise<ApiResponse<T>> {
	const accessToken = await getToken();
	const config: CreateAxiosDefaults = {
		baseURL: apiServerUrl,
		headers: {
			Authorization: `Bearer ${accessToken}`,
		},
		withCredentials: true,
	};
	const api: AxiosInstance = axios.create(config);
	api.interceptors.response.use(
		(response) => response,
		(error: AxiosError) => Promise.reject(error)
	);
	try {
		const response: AxiosResponse<T> = await api.request({
			method,
			url: url,
			params,
			data,
		});
		return { status: response.status as 200, data: response.data };
	} catch (error) {
		if (axios.isAxiosError(error) && error.response) {
			const { status, data } = error.response;
			switch (status) {
				case 400:
					return { status: 400, data: data as BadRequestResponse };
				case 401:
					return { status: 401, data: data as UnauthorizedResponse };
				case 404:
					return { status: 404, data: data as NotFoundResponse };
				case 422:
					return { status: 422, data: data as UnprocessableEntityResponse };
				case 500:
					return { status: 500, data: data as ServerErrorResponse };
			}
		}
		throw error; // Re-throw if it's an unexpected error
	}
}

// Proxies
async function getProxiesVerbose(host?: string, port?: number, paginationOptions: PaginationOptions = {}): Promise<PaginatedResults<Proxy>> {
	return request('GET', '/proxies/verbose', {}, {
		host,
		port,
		...paginationOptions
	});
}

async function createProxy(proxy: CreateProxyRequest): Promise<ApiResponse<Proxy>> {
	const result = await makeApiCall<Proxy>("POST", "/proxies/simple", proxy);
	if (result.status == 200 && proxy.GroupId) {
		await request("POST", `/proxies/proxygroups`, {
			ProxyId: result.data.ProxyId,
			GroupId: proxy.GroupId,
		});
	}
	return result;
}

async function deleteProxy(id: number): Promise<void> {
	await request('DELETE', `/proxies/${id}`);
}


// Providers
async function getProviders(name?: string, billingInfo?: string, paginationOptions: PaginationOptions = {}): Promise<PaginatedResults<Provider>> {
	return request('GET', '/proxies/providers', {}, {
		name,
		billingInfo,
		...paginationOptions
	});
}

async function createProvider(provider: CreateProviderRequest): Promise<Provider> {
	return request('POST', '/proxies/providers', provider);
}

async function updateProvider(provider: UpdateProviderRequest): Promise<Provider> {
	return request('PUT', `/proxies/providers/${provider.ProviderId}`, {
		Name: provider.Name,
		BillingInfo: provider.BillingInfo,
		PricingType: provider.PricingType,
		ProxyType: provider.ProxyType
	});
}

async function deleteProvider(id: number): Promise<void> {
	await request('DELETE', `/proxies/providers/${id}`);
}

// Pricing Models
async function getPricingModels(): Promise<PaginatedResults<PricingModel>> {
	return request('GET', '/proxies/pricing');
}

// Credentials
async function getCredentials(paginationOptions: PaginationOptions = {}): Promise<PaginatedResults<Credential>> {
	return request('GET', '/proxies/credentials', {}, paginationOptions);
}

async function createCredential(credential: CreateCredentialRequest): Promise<Credential> {
	return request('POST', '/proxies/credentials', credential);
}

async function updateCredential(credential: UpdateCredentialRequest): Promise<Credential> {
	return request('PUT', `/proxies/credentials/${credential.CredentialId}`, credential);
}

async function deleteCredential(id: number): Promise<void> {
	await request('DELETE', `/proxies/credentials/${id}`);
}

async function getGroups(paginationOptions: PaginationOptions = {}): Promise<PaginatedResults<Group>> {
	return request('GET', '/proxies/groups', {}, paginationOptions);
}

async function deleteGroup(groupId: number): Promise<void> {
	await request('DELETE', `/proxies/groups/${groupId}`);
}

async function updateGroup(groupId: number, updatedGroup: Group): Promise<Group> {
	return request('PUT', `/proxies/groups/${groupId}`, updatedGroup);
}

async function createGroup(group: Partial<Group>): Promise<Group> {
	return request('POST', '/proxies/groups', group);
}

export default {
	getProxiesVerbose,
	createProxy,
	deleteProxy,
	getProviders,
	createProvider,
	updateProvider,
	deleteProvider,
	getPricingModels,
	getCredentials,
	createCredential,
	updateCredential,
	deleteCredential,
	getGroups,
	updateGroup,
	deleteGroup,
	createGroup
}

<template>
	<div class="relative flex-grow" v-click-outside="hideDropdown">
		<va-input
			v-model="localDisplay"
			class="w-full"
			:id="id"
			:name="name"
			:label="label"
			:required="isRequired"
			:placeholder="placeholder"
			@focus="fetchData"
			@input="() => emit('update:localDisplay', localDisplay)"
		/>
		<div class="absolute w-full bg-gray-200 border border-gray-300 rounded-md shadow-lg z-50 max-h-64 overflow-auto"
			 v-if="showDropdown && dataNotEmpty">
			<div v-for="item in data" :key="item[idKey]" @click="selectItem(item)"
				 class="p-2 hover:bg-gray-400 cursor-pointer text-black">
				<template v-if="Array.isArray(displayKey)">
					<div v-for="(key, index) in displayKey" :key="index">
						{{ key }}: {{ item[key] }}
					</div>
				</template>
				<template v-else>
					{{ item[displayKey] }}
				</template>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import {computed, watch, ref, withDefaults} from 'vue';

const emit = defineEmits();
type ItemType = {
	id: string;
	name: string;
	[key: string]: string;
};

type SelectItemType = {
	id: string;
	name: string;
	[key: string]: string;
};

const props = withDefaults(defineProps<{
	id: string;
	name: string;
	label: string;
	isRequired: boolean;
	placeholder: string;
	fetchData?: () => Promise<void>;
	selectItem: (_item: SelectItemType) => void;
	display: string;
	data: () => Array<ItemType>;
	idKey: string;
	displayKey: string | string[];
	updateDisplay: (_value: string) => void;
}>(), {
	fetchData: () => Promise.resolve(),
	selectItem: (_item: SelectItemType) => {
	},
	updateDisplay: (_value: string) => {
	},
	data: () => [],
	id: '',
	label: '',
	idKey: '',
	displayKey: ''
});

const showDropdown = ref(false);
const dataNotEmpty = computed(() => {
	const dataArray = typeof props.data === 'function' ? props.data() : [];
	return dataArray.length > 0;
});
const data = ref<ItemType[]>([]);

const fetchData = async () => {
	await props.fetchData();
	const dataArray = typeof props.data === 'function' ? props.data() : [];
	if (dataArray) {
		data.value = dataArray;
		showDropdown.value = dataArray.length > 0;
	}
};

const selectItem = (item: SelectItemType) => {
	props.selectItem(item);
	hideDropdown();
	// Check if displayKey is an array
	if (Array.isArray(props.displayKey)) {
		// Join the values of the keys in the array with a space
		const displayValue = item[props.displayKey[0]];
		props.updateDisplay(displayValue);
	} else {
		props.updateDisplay(item[props.displayKey]);
	}
};

const localDisplay = ref(props.display);

watch(() => props.display, (newDisplay) => {
	localDisplay.value = newDisplay;
});

watch(localDisplay, (newDisplay) => {
	props.updateDisplay(newDisplay);
});

const hideDropdown = () => {
	showDropdown.value = false;
};
</script>



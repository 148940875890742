<script setup lang="ts">
	import { onMounted } from 'vue';
	import { useRouter } from 'vue-router';
	import { getInstance } from '@/auth/authWrapper';

	const router = useRouter();
	const auth0 = getInstance();

	onMounted(async () => {
		if (auth0) {
			try {
				await auth0.handleRedirectCallback();
				await router.push('/');
			} catch (error) {
				console.error('Error handling redirect:', error);
			}
		}
	});
</script>

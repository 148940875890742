<template>
	<p class="font-bold text-2xl pb-3 text-center">Groups</p>

	<DataTable
		v-model:current-page="currentPage"
		v-model:page-size="pageSize"
		:items="groups"
		:columns="columns"
		:loading="isLoading"
		:clickable="true"
		:can-edit="false"
		:can-delete="false"
		:on-create="async () => openModalToAddNewGroup()"
		:on-click="async (event) => await navigateToGroup(event)"
		class="va-table va-table--hoverable"
		@row:click="navigateToGroup"
		>
		<template #cell(actions)="{ row }">
				<VaButton
					preset="plain"
					icon="edit"
					@click.stop="openModalToEditItemById(row.rowData)"/>
				<VaButton
					preset="plain"
					color="danger"
					icon="delete"
					class="ml-3"
					@click.stop="openModalToDeleteItemById(row.rowData.GroupId)"/>
		</template>
		<template #cell(CreatedDateUtc)="{ value }">
			{{ value && formatDateTime(value) }}
		</template>
	</DataTable>

	<VaModal
		v-model="isEditModalOpen"
		title="Edit Group"
		size="small"
		@ok="confirmEdit"
		@cancel="resetEditItemId"
	>
		<p>Edit the group details:</p>
		<div v-if="editedGroup">
			<VaInput v-model="editedGroup.GroupName" class="my-6" label="Name" />
			<VaInput v-model="editedGroup.GroupDescription" class="my-6" label="Description" />
		</div>
	</VaModal>

	<VaModal
		v-model="isDeleteModalOpen"
		title="Confirm Deletion"
		size="small"
		@ok="confirmDelete"
		@cancel="resetDeleteItemId"
	>
		<p>Are you sure you want to delete this group?</p>
	</VaModal>

	<VaModal
		v-model="isNewGroupModalOpen"
		title="Add New Group"
		size="small"
		@ok="confirmAddNewGroup"
		@cancel="resetNewGroupData"
	>
		<div v-if="newGroup">
			<VaInput v-model="newGroup.GroupName" class="my-6" label="Group Name" />
			<VaInput v-model="newGroup.GroupDescription" class="my-6" label="Description" />
		</div>
	</VaModal>
</template>

<script setup lang="ts">
	import DataTable from "@/components/DataTable.vue";
	import { ref, onMounted, watch } from 'vue';
	import { useRouter } from 'vue-router';
	import {Group} from "@/models/Group";
	import {formatDateTime} from "@/utils/datetime";
	import api from "@/services/proxy-api";
	import {PaginatedResults} from "@/models/PaginatedResults";

	let itemId: number | null = null;
	let editItemId: number | null = null;
	const router = useRouter();
	const editedGroup = ref<Group | null>(null);
	const groups = ref<PaginatedResults<Group>>({records: [], pagination: {page: 0, page_size: 0, total_records: 0, total_pages: 0}});
	const newGroup = ref<Partial<Group> | null>(null);
	const currentPage = ref(1);
	const pageSize = ref(10);
	const isDeleteModalOpen = ref(false);
	const isEditModalOpen = ref(false);
	const isLoading = ref(true);
	const isNewGroupModalOpen = ref(false);

	const columns =  [
		{ key: 'GroupId', sortable: true, label: 'Group Id' },
		{ key: 'GroupName', sortable: true, label: 'Name' },
		{ key: 'GroupDescription', sortable: true, label: 'Description' },
		{ key: 'CreatedDateUtc', sortable: true, label: 'Created Date' },
		{ key: 'actions', sortable: false, label: 'Actions' }
	];

	const openModalToEditItemById = (group: Group) => {
		editItemId = group.GroupId;
		editedGroup.value = { ...group };
		isEditModalOpen.value = true;
	};

	const confirmEdit = () => {
		if (editItemId !== null && editedGroup.value !== null) {
			editGroup(editItemId, editedGroup.value);
			resetEditItemId();
		}
	};

	const resetEditItemId = () => {
		editItemId = null;
		editedGroup.value = null;
		isEditModalOpen.value = false;
	};
	const openModalToDeleteItemById = (id: number) => {
		itemId = id;
		isDeleteModalOpen.value = true;
	};

	const confirmDelete = () => {
		if (itemId !== null) {
			deleteGroup(itemId);
			resetDeleteItemId();
		}
	};

	const resetDeleteItemId = () => {
		itemId = null;
		isDeleteModalOpen.value = false;
	};

	const openModalToAddNewGroup = () => {
		newGroup.value = {GroupName: '', GroupDescription: '' };
		isNewGroupModalOpen.value = true;
	};

	const confirmAddNewGroup = async () => {
		if (newGroup.value !== null) {
			await addNewGroup(newGroup.value);
			resetNewGroupData();
		}
	};

	const resetNewGroupData = () => {
		newGroup.value = null;
		isNewGroupModalOpen.value = false;
	};

	const navigateToGroup = async (event: any) => {
		router.push(`/groups/${event.item.GroupId}`);
	};

	const fetchGroups = async () => {
		isLoading.value = true;
		groups.value = await api.getGroups({ pageSize: pageSize.value, pageIndex: currentPage.value });
		isLoading.value = false;
	};

	const deleteGroup = async (groupId: number) => {
		await api.deleteGroup(groupId);
		// Refresh the groups after deletion
		await fetchGroups();
	};

	const editGroup = async (groupId: number, updatedGroup: Group) => {
		await api.updateGroup(groupId, updatedGroup);
		// Refresh the groups after editing
		await fetchGroups();
	};

	const addNewGroup = async (group: Partial<Group>) => {
		await api.createGroup(group);
		// Refresh the groups after adding
		await fetchGroups();
	};

	onMounted(fetchGroups);

watch(currentPage, async (newVal, oldVal) => {
	if (newVal !== oldVal) {
		await fetchGroups();
	}
});

watch(pageSize, async (newVal, oldVal) => {
	if (newVal !== oldVal) {
		await fetchGroups();
	}
});
</script>

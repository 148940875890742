// authWrapper.ts
import {Auth0Plugin, createAuth0} from '@auth0/auth0-vue';

let instance: Auth0Plugin | null = null;

export const getInstance = (): Auth0Plugin | null => instance;

export const useAuth0 = (options: {
	domain: string,
	clientId: string,
	authorizationParams: {
		redirect_uri: string,
		audience: string,
		organization: string,
	},
	cacheLocation: 'localstorage',
}): Auth0Plugin => {
	if (instance) return instance;

	instance = createAuth0({
		domain: options.domain,
		clientId: options.clientId,
		authorizationParams: {
			redirect_uri: options.authorizationParams.redirect_uri,
			audience: options.authorizationParams.audience,
			organization: options.authorizationParams.organization,
		},
		cacheLocation: options.cacheLocation,
	});

	return instance as Auth0Plugin;
};

export const getAccessTokenSilently = async (): Promise<string | null> => {
	if (!instance) {
		return null;
	}

	try {
		return await instance.getAccessTokenSilently();
	} catch (error) {
		return null;
	}
};

// main.ts
import {createApp} from 'vue'
import App from './App.vue'
import { createVuestic } from "vuestic-ui";
import 'vuestic-ui/dist/vuestic-ui.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import 'vuestic-ui/styles/essential.css';
import 'vuestic-ui/styles/typography.css';
import router from './router'
import VueSweetalert2 from 'vue-sweetalert2';
import './style.css'
import vClickOutside from "click-outside-vue3"
import { useAuth0 } from './auth/authWrapper';

const auth0 = useAuth0({
	domain: import.meta.env.VITE_AUTH0_DOMAIN,
	clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
	authorizationParams: {
		redirect_uri: import.meta.env.VITE_AUTH0_CALLBACK_URL,
		audience: import.meta.env.VITE_AUTH0_AUDIENCE,
		organization: import.meta.env.VITE_AUTH0_ORGANIZATION
	},
	cacheLocation: 'localstorage',
});

createApp(App)
	.use(createVuestic())
	.use(router)
	.use(VueSweetalert2)
	.use(vClickOutside)
	.use(auth0 as any)
	.mount('#app')

<script setup lang="ts">
import { getInstance } from '@/auth/authWrapper';
const auth0 = getInstance();
</script>

<template>
  <div class="flex justify-center items-center h-96">
    <div class="text-center">
      <h1 class="font-bold text-gray-900 text-center text-3xl mb-4">Welcome to the Proxy Manager</h1>
      <p class=" mb-8">Please log in to access this application</p>
      <VaButton @click="() => auth0?.loginWithRedirect()" icon="login" round >Login</VaButton>
    </div>
  </div>
</template>

<style scoped>
.landing-page h1 {
	margin-bottom: 20px;
}
</style>

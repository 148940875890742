<template>
  <div class="crud-table">
    <div class="table-options">
      <div class="float-left mb-3 flex ">
        <div class="per-page-select mr-5">
          Show
          <VaSelect
              class="mx-2 w-20"
              v-model="pageSize"
              :options="[10, 20, 50, 100, 200, 500]"
          />
          per page
        </div>
        <slot name="top-left"></slot>
      </div>
      <div class="float-right mb-3">
        <slot name="top-right"></slot>
        <VaButton
						v-if="canDelete"
            :disabled="!selectedItems.length"
            class="mr-3"
            color="danger"
            icon="delete"
            round
            @click="async () => {
              await props.onBulkDelete(selectedItems);
            }"
        >
          Delete Selected
        </VaButton>
        <VaButton
						v-if="canCreate"
            icon="add"
            round
            @click="props.onCreate"
        >
          Create
        </VaButton>
      </div>
    </div>

    <VaDataTable
        class="mb-3"
        striped
        selectable
        v-model="selectedItems"
        :items="props.items.records"
        :columns="props.columns"
        :loading="props.loading"
        :clickable="props.clickable"
        @row:click="props.onClick"
        :filter-method="props.filterMethod">

			<template v-for="(value, name) in $slots" v-slot:[name]="slotData">
				<slot :name="name" v-bind="slotData" />
			</template>

      <template #cell(actions)="item">
				<VaButton
					v-if="canEdit"
					preset="plain"
					icon="edit"
					@click="props.onEdit(item.rowData)"
				/>
        <VaButton
						v-if="canDelete"
            preset="plain"
            color="danger"
            icon="delete"
            class="ml-3"
            @click="props.onDelete(item.rowData)"
        />
      </template>
    </VaDataTable>
    <VaPagination v-if="!props.loading"
        class="justify-center mt-3"
        v-model="currentPage"
        :visible-pages="10"
        :pages="props.items.pagination.total_pages" />
  </div>

</template>
<script setup lang="ts">
import { DataTableColumn, DataTableFilterMethod } from "vuestic-ui/web-components";
import {PaginatedResults} from "@/models/PaginatedResults";

const selectedItems = defineModel('selectedItems');
const currentPage = defineModel('currentPage');
const pageSize = defineModel('pageSize');

export type Props = {
  items: PaginatedResults<any>,
  columns: DataTableColumn[],
  onCreate?: () => Promise<void>,
  onClick?: (event: any) => Promise<void>,
	onEdit?: (item: any) => Promise<void>,
  onDelete?: (item: any) => Promise<void>,
  onBulkDelete?: (items: any[]) => Promise<void>,
	canCreate?: boolean,
	canEdit?: boolean,
	canDelete?: boolean,
  loading: boolean,
  clickable?: boolean
  filterMethod?: DataTableFilterMethod
}

const props = withDefaults(defineProps<Props>(), {
  onCreate: () => Promise.resolve(),
  onClick: () => Promise.resolve(),
	onEdit: () => Promise.resolve(),
  onDelete: () => Promise.resolve(),
  onBulkDelete: () => Promise.resolve(),
	canCreate: true,
	canEdit: true,
	canDelete: true,
  loading: false,
  clickable: false,
  filterMethod: () => true
});
</script>

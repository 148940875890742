<template>
	<p class="font-bold text-2xl pb-3 text-center">{{ groupName }}</p>
	<div class="grid">
		<VaButton
			round
			icon="add"
			class="justify-self-end"
			@click="openModalToAddNewGroup"
		>Add Proxy Group</VaButton>
	</div>
	<div>
		<VaDataTable
			:items="proxies"
			:columns="columns"
			:loading="isLoading"
			class="va-table va-table--hoverable"
		>
		</VaDataTable>
	</div>
	<VaModal
		v-model="isNewGroupModalOpen"
		title="Add New Proxy Group"
		@ok="confirmAddNewGroup"
		@cancel="resetNewGroupData"
	>
		<div v-if="newProxyGroup">
			<Dropdown
				:id="'ProxyId'"
				:name="'ProxyId'"
				:label="'Choose a proxy to add to this group:'"
				:display.sync="displayValues['ProxyId'] || ''"
				:isRequired="true"
				:placeholder="'Proxy'"
				:selectItem="selectItem"
				:data="() => dataValues['ProxyId']"
				:displayKey="displayKey"
				:idKey="'ProxyId'"
				:updateDisplay="(value: string) => displayValues['ProxyId'] = value"
			/>
		</div>
	</VaModal>
</template>

<script setup lang="ts">
	import { ref, onMounted, reactive } from 'vue';
	import Dropdown from './Dropdown.vue';
	import axios from 'axios';
	import { getToken } from '@/utils/auth';
	import { useRoute } from 'vue-router';
	import {Proxy} from "@/models/Proxy";

	const route = useRoute();
	const groupId = +route.params.groupId;
	const groupName = ref('');
	const isLoading = ref(true);
	const apiServerUrl = import.meta.env.VITE_API_SERVER_URL;
	const proxies = ref<Partial<Proxy>[]>([]);
	const isNewGroupModalOpen = ref(false);
	const newProxyGroup = ref<Partial<ProxyGroup> | null>(null);
	const displayValues = reactive<{ [key: string]: string }>({});
	const dataValues = reactive<{ [key: string]: any[] }>({});
	const displayKey = ['Host', 'Port'];
	const columns = [
		{ key: 'ProxyId', sortable: true, label: 'ID' },
		{ key: 'Host', sortable: true, label: 'Host' },
		{ key: 'Port', sortable: true, label: 'Port' },
	];

	interface ProxyGroup {
		CreatedDateUtc: string;
		GroupId: number;
		ProxyId: number;
	}

	const openModalToAddNewGroup = async () => {
		newProxyGroup.value = {ProxyId: undefined};
		await fetchData('ProxyId');
		isNewGroupModalOpen.value = true;
	};

	const confirmAddNewGroup = async () => {
		if (newProxyGroup.value !== null) {
			newProxyGroup.value.GroupId = groupId;
			await addNewGroup(newProxyGroup.value);
			resetNewGroupData();
		}
	};

	const resetNewGroupData = () => {
		newProxyGroup.value = null;
		isNewGroupModalOpen.value = false;
	};

	const selectItem = (item: any) => {
		if (newProxyGroup.value) {
			newProxyGroup.value.ProxyId = item.ProxyId;
		}
	};

	const fetchProxyGroups = async () => {
		isLoading.value = true;
		const accessToken = await getToken();
		const response = await axios.get(`${apiServerUrl}/proxies/proxyGroups?groupId=${groupId}`, {
			headers: {
				'Authorization': `Bearer ${accessToken}`
			},
			withCredentials: true
		});
		if (response.status === 200) {
			const proxyGroups = response.data.records;
			if (proxyGroups.length > 0 && proxyGroups[0].Group && proxyGroups[0].Group.GroupName) {
				groupName.value = proxyGroups[0].Group.GroupName;
				proxies.value = [];
				for (const proxyGroup of proxyGroups) {
					const proxyResponse = await axios.get(`${apiServerUrl}/proxies/${proxyGroup.ProxyId}`, {
						headers: {
							'Authorization': `Bearer ${accessToken}`
						},
						withCredentials: true
					});
					if (proxyResponse.status === 200) {
						const proxy = proxyResponse.data;
						proxies.value.push({
							ProxyId: proxy[0].ProxyId,
							Host: proxy[0].Host,
							Port: proxy[0].Port
						});
					}
				}
			}else {
				const groupResponse = await axios.get(`${apiServerUrl}/proxies/groups/${groupId}`, {
					headers: {
						'Authorization': `Bearer ${accessToken}`
					},
					withCredentials: true
				});
				if (groupResponse.status === 200) {
					const group = groupResponse.data;
					groupName.value = group.GroupName;
				}
			}
			isLoading.value = false;
		}
	};

	const addNewGroup = async (group: Partial<ProxyGroup>) => {
		const accessToken = await getToken();
		await axios.post(`${apiServerUrl}/proxies/proxygroups`, group, {
			headers: {
				'Authorization': `Bearer ${accessToken}`
			},
			withCredentials: true
		});

		await fetchProxyGroups();
	};

	onMounted(fetchProxyGroups);

	const fetchData = async (key: string) => {
		const accessToken = await getToken();
		const response = await axios.get(`${apiServerUrl}/proxies/verbose`, {
			headers: {
				'Authorization': `Bearer ${accessToken}`
			},
			withCredentials: true
		});

		if (response.status === 200) {
			dataValues[key] = response.data.records;
		}
	};
</script>

<script setup lang="ts">
import {getInstance} from '@/auth/authWrapper';

const auth0 = getInstance();
const isAuthenticated = auth0?.isAuthenticated;

</script>

<template>
	<VaNavbar
		color="#282F69"
		class="h-24 px-5"
	>
		<template #left>
			<VaNavbarItem class="logo">
				<img src="https://tesseradata.com/wp-content/uploads/2023/12/Tessera_favicon-150x150.png" alt="Logo"/>
				Proxy Manager
			</VaNavbarItem>
		</template>
		<template #right>
			<template v-if="isAuthenticated">
				<va-navbar-item>
					<router-link to="/proxies" class="text-white">Proxies</router-link>
				</va-navbar-item>
				<va-navbar-item>
					<router-link to="/groups" class="text-white">Groups</router-link>
				</va-navbar-item>
				<va-navbar-item>
					<router-link to="/providers" class="text-white">Providers</router-link>
				</va-navbar-item>
				<va-navbar-item>
					<router-link to="/credentials" class="text-white">Credentials</router-link>
				</va-navbar-item>
				<va-navbar-item>
					<router-link to="/logout" class="text-white">Logout</router-link>
				</va-navbar-item>
			</template>
		</template>
	</VaNavbar>
</template>

<style scoped>
.logo {
	font-weight: 600;
	font-size: 1.2rem;

	img[alt="Logo"] {
		display: block;
		height: 45px;
		margin-right: 12px;
	}
}
</style>
